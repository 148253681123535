/* eslint-disable */
import _ui_user from '../../../../public/images/icons/ui/user.svg?url'
import _ui_userSource from '!!raw-loader!../../../../public/images/icons/ui/user.svg'
import _ui_twitter from '../../../../public/images/icons/ui/twitter.svg?url'
import _ui_twitterSource from '!!raw-loader!../../../../public/images/icons/ui/twitter.svg'
import _ui_timer from '../../../../public/images/icons/ui/timer.svg?url'
import _ui_timerSource from '!!raw-loader!../../../../public/images/icons/ui/timer.svg'
import _ui_status_warning from '../../../../public/images/icons/ui/status-warning.svg?url'
import _ui_status_warningSource from '!!raw-loader!../../../../public/images/icons/ui/status-warning.svg'
import _ui_status_success from '../../../../public/images/icons/ui/status-success.svg?url'
import _ui_status_successSource from '!!raw-loader!../../../../public/images/icons/ui/status-success.svg'
import _ui_status_error from '../../../../public/images/icons/ui/status-error.svg?url'
import _ui_status_errorSource from '!!raw-loader!../../../../public/images/icons/ui/status-error.svg'
import _ui_shield_with_checkmark from '../../../../public/images/icons/ui/shield-with-checkmark.svg?url'
import _ui_shield_with_checkmarkSource from '!!raw-loader!../../../../public/images/icons/ui/shield-with-checkmark.svg'
import _ui_safety from '../../../../public/images/icons/ui/safety.svg?url'
import _ui_safetySource from '!!raw-loader!../../../../public/images/icons/ui/safety.svg'
import _ui_reward from '../../../../public/images/icons/ui/reward.svg?url'
import _ui_rewardSource from '!!raw-loader!../../../../public/images/icons/ui/reward.svg'
import _ui_pig from '../../../../public/images/icons/ui/pig.svg?url'
import _ui_pigSource from '!!raw-loader!../../../../public/images/icons/ui/pig.svg'
import _ui_open_book from '../../../../public/images/icons/ui/open-book.svg?url'
import _ui_open_bookSource from '!!raw-loader!../../../../public/images/icons/ui/open-book.svg'
import _ui_no_icon from '../../../../public/images/icons/ui/no-icon.svg?url'
import _ui_no_iconSource from '!!raw-loader!../../../../public/images/icons/ui/no-icon.svg'
import _ui_money from '../../../../public/images/icons/ui/money.svg?url'
import _ui_moneySource from '!!raw-loader!../../../../public/images/icons/ui/money.svg'
import _ui_logo from '../../../../public/images/icons/ui/logo.svg?url'
import _ui_logoSource from '!!raw-loader!../../../../public/images/icons/ui/logo.svg'
import _ui_locked from '../../../../public/images/icons/ui/locked.svg?url'
import _ui_lockedSource from '!!raw-loader!../../../../public/images/icons/ui/locked.svg'
import _ui_loading from '../../../../public/images/icons/ui/loading.svg?url'
import _ui_loadingSource from '!!raw-loader!../../../../public/images/icons/ui/loading.svg'
import _ui_loader from '../../../../public/images/icons/ui/loader.svg?url'
import _ui_loaderSource from '!!raw-loader!../../../../public/images/icons/ui/loader.svg'
import _ui_info from '../../../../public/images/icons/ui/info.svg?url'
import _ui_infoSource from '!!raw-loader!../../../../public/images/icons/ui/info.svg'
import _ui_exit from '../../../../public/images/icons/ui/exit.svg?url'
import _ui_exitSource from '!!raw-loader!../../../../public/images/icons/ui/exit.svg'
import _ui_discord from '../../../../public/images/icons/ui/discord.svg?url'
import _ui_discordSource from '!!raw-loader!../../../../public/images/icons/ui/discord.svg'
import _ui_deposit_and_withdrawal from '../../../../public/images/icons/ui/deposit-and-withdrawal.svg?url'
import _ui_deposit_and_withdrawalSource from '!!raw-loader!../../../../public/images/icons/ui/deposit-and-withdrawal.svg'
import _ui_coin from '../../../../public/images/icons/ui/coin.svg?url'
import _ui_coinSource from '!!raw-loader!../../../../public/images/icons/ui/coin.svg'
import _ui_code from '../../../../public/images/icons/ui/code.svg?url'
import _ui_codeSource from '!!raw-loader!../../../../public/images/icons/ui/code.svg'
import _ui_close from '../../../../public/images/icons/ui/close.svg?url'
import _ui_closeSource from '!!raw-loader!../../../../public/images/icons/ui/close.svg'
import _ui_circular_gear from '../../../../public/images/icons/ui/circular-gear.svg?url'
import _ui_circular_gearSource from '!!raw-loader!../../../../public/images/icons/ui/circular-gear.svg'
import _ui_check from '../../../../public/images/icons/ui/check.svg?url'
import _ui_checkSource from '!!raw-loader!../../../../public/images/icons/ui/check.svg'
import _ui_arrow_down_circle_outline from '../../../../public/images/icons/ui/arrow-down-circle-outline.svg?url'
import _ui_arrow_down_circle_outlineSource from '!!raw-loader!../../../../public/images/icons/ui/arrow-down-circle-outline.svg'
import _tokens_xdai from '../../../../public/images/icons/tokens/xdai.svg?url'
import _tokens_xdaiSource from '!!raw-loader!../../../../public/images/icons/tokens/xdai.svg'
import _tokens_wxdai from '../../../../public/images/icons/tokens/wxdai.svg?url'
import _tokens_wxdaiSource from '!!raw-loader!../../../../public/images/icons/tokens/wxdai.svg'
import _tokens_wif from '../../../../public/images/icons/tokens/wif.svg?url'
import _tokens_wifSource from '!!raw-loader!../../../../public/images/icons/tokens/wif.svg'
import _tokens_weth from '../../../../public/images/icons/tokens/weth.svg?url'
import _tokens_wethSource from '!!raw-loader!../../../../public/images/icons/tokens/weth.svg'
import _tokens_wchz from '../../../../public/images/icons/tokens/wchz.svg?url'
import _tokens_wchzSource from '!!raw-loader!../../../../public/images/icons/tokens/wchz.svg'
import _tokens_usdt from '../../../../public/images/icons/tokens/usdt.svg?url'
import _tokens_usdtSource from '!!raw-loader!../../../../public/images/icons/tokens/usdt.svg'
import _tokens_usdc from '../../../../public/images/icons/tokens/usdc.svg?url'
import _tokens_usdcSource from '!!raw-loader!../../../../public/images/icons/tokens/usdc.svg'
import _tokens_shib from '../../../../public/images/icons/tokens/shib.svg?url'
import _tokens_shibSource from '!!raw-loader!../../../../public/images/icons/tokens/shib.svg'
import _tokens_popcat from '../../../../public/images/icons/tokens/popcat.svg?url'
import _tokens_popcatSource from '!!raw-loader!../../../../public/images/icons/tokens/popcat.svg'
import _tokens_pol from '../../../../public/images/icons/tokens/pol.svg?url'
import _tokens_polSource from '!!raw-loader!../../../../public/images/icons/tokens/pol.svg'
import _tokens_pepe from '../../../../public/images/icons/tokens/pepe.svg?url'
import _tokens_pepeSource from '!!raw-loader!../../../../public/images/icons/tokens/pepe.svg'
import _tokens_floki from '../../../../public/images/icons/tokens/floki.svg?url'
import _tokens_flokiSource from '!!raw-loader!../../../../public/images/icons/tokens/floki.svg'
import _tokens_eth from '../../../../public/images/icons/tokens/eth.svg?url'
import _tokens_ethSource from '!!raw-loader!../../../../public/images/icons/tokens/eth.svg'
import _tokens_doge from '../../../../public/images/icons/tokens/doge.svg?url'
import _tokens_dogeSource from '!!raw-loader!../../../../public/images/icons/tokens/doge.svg'
import _tokens_dai from '../../../../public/images/icons/tokens/dai.svg?url'
import _tokens_daiSource from '!!raw-loader!../../../../public/images/icons/tokens/dai.svg'
import _tokens_chz from '../../../../public/images/icons/tokens/chz.svg?url'
import _tokens_chzSource from '!!raw-loader!../../../../public/images/icons/tokens/chz.svg'
import _tokens_bonk from '../../../../public/images/icons/tokens/bonk.svg?url'
import _tokens_bonkSource from '!!raw-loader!../../../../public/images/icons/tokens/bonk.svg'
import _tokens_bnb from '../../../../public/images/icons/tokens/bnb.svg?url'
import _tokens_bnbSource from '!!raw-loader!../../../../public/images/icons/tokens/bnb.svg'
import _networks_solana from '../../../../public/images/icons/networks/solana.svg?url'
import _networks_solanaSource from '!!raw-loader!../../../../public/images/icons/networks/solana.svg'
import _networks_polygon from '../../../../public/images/icons/networks/polygon.svg?url'
import _networks_polygonSource from '!!raw-loader!../../../../public/images/icons/networks/polygon.svg'
import _networks_linea from '../../../../public/images/icons/networks/linea.svg?url'
import _networks_lineaSource from '!!raw-loader!../../../../public/images/icons/networks/linea.svg'
import _networks_gnosis from '../../../../public/images/icons/networks/gnosis.svg?url'
import _networks_gnosisSource from '!!raw-loader!../../../../public/images/icons/networks/gnosis.svg'
import _networks_eth from '../../../../public/images/icons/networks/eth.svg?url'
import _networks_ethSource from '!!raw-loader!../../../../public/images/icons/networks/eth.svg'
import _networks_chiliz from '../../../../public/images/icons/networks/chiliz.svg?url'
import _networks_chilizSource from '!!raw-loader!../../../../public/images/icons/networks/chiliz.svg'
import _networks_bnb from '../../../../public/images/icons/networks/bnb.svg?url'
import _networks_bnbSource from '!!raw-loader!../../../../public/images/icons/networks/bnb.svg'
import _networks_base from '../../../../public/images/icons/networks/base.svg?url'
import _networks_baseSource from '!!raw-loader!../../../../public/images/icons/networks/base.svg'
import _networks_arbitrum from '../../../../public/images/icons/networks/arbitrum.svg?url'
import _networks_arbitrumSource from '!!raw-loader!../../../../public/images/icons/networks/arbitrum.svg'
import _bg_digits_two from '../../../../public/images/icons/bg-digits/two.svg?url'
import _bg_digits_twoSource from '!!raw-loader!../../../../public/images/icons/bg-digits/two.svg'
import _bg_digits_three from '../../../../public/images/icons/bg-digits/three.svg?url'
import _bg_digits_threeSource from '!!raw-loader!../../../../public/images/icons/bg-digits/three.svg'
import _bg_digits_one from '../../../../public/images/icons/bg-digits/one.svg?url'
import _bg_digits_oneSource from '!!raw-loader!../../../../public/images/icons/bg-digits/one.svg'
import _bg_digits_four from '../../../../public/images/icons/bg-digits/four.svg?url'
import _bg_digits_fourSource from '!!raw-loader!../../../../public/images/icons/bg-digits/four.svg'
import _backers_yelay from '../../../../public/images/icons/backers/yelay.svg?url'
import _backers_yelaySource from '!!raw-loader!../../../../public/images/icons/backers/yelay.svg'

const isServer = typeof window === 'undefined'

const icons = {
  'ui/user': { src: _ui_user.src, source: isServer && _ui_userSource, width: _ui_user.width, height: _ui_user.height, aspect: _ui_user.width/_ui_user.height }, 
  'ui/twitter': { src: _ui_twitter.src, source: isServer && _ui_twitterSource, width: _ui_twitter.width, height: _ui_twitter.height, aspect: _ui_twitter.width/_ui_twitter.height }, 
  'ui/timer': { src: _ui_timer.src, source: isServer && _ui_timerSource, width: _ui_timer.width, height: _ui_timer.height, aspect: _ui_timer.width/_ui_timer.height }, 
  'ui/status-warning': { src: _ui_status_warning.src, source: isServer && _ui_status_warningSource, width: _ui_status_warning.width, height: _ui_status_warning.height, aspect: _ui_status_warning.width/_ui_status_warning.height }, 
  'ui/status-success': { src: _ui_status_success.src, source: isServer && _ui_status_successSource, width: _ui_status_success.width, height: _ui_status_success.height, aspect: _ui_status_success.width/_ui_status_success.height }, 
  'ui/status-error': { src: _ui_status_error.src, source: isServer && _ui_status_errorSource, width: _ui_status_error.width, height: _ui_status_error.height, aspect: _ui_status_error.width/_ui_status_error.height }, 
  'ui/shield-with-checkmark': { src: _ui_shield_with_checkmark.src, source: isServer && _ui_shield_with_checkmarkSource, width: _ui_shield_with_checkmark.width, height: _ui_shield_with_checkmark.height, aspect: _ui_shield_with_checkmark.width/_ui_shield_with_checkmark.height }, 
  'ui/safety': { src: _ui_safety.src, source: isServer && _ui_safetySource, width: _ui_safety.width, height: _ui_safety.height, aspect: _ui_safety.width/_ui_safety.height }, 
  'ui/reward': { src: _ui_reward.src, source: isServer && _ui_rewardSource, width: _ui_reward.width, height: _ui_reward.height, aspect: _ui_reward.width/_ui_reward.height }, 
  'ui/pig': { src: _ui_pig.src, source: isServer && _ui_pigSource, width: _ui_pig.width, height: _ui_pig.height, aspect: _ui_pig.width/_ui_pig.height }, 
  'ui/open-book': { src: _ui_open_book.src, source: isServer && _ui_open_bookSource, width: _ui_open_book.width, height: _ui_open_book.height, aspect: _ui_open_book.width/_ui_open_book.height }, 
  'ui/no-icon': { src: _ui_no_icon.src, source: isServer && _ui_no_iconSource, width: _ui_no_icon.width, height: _ui_no_icon.height, aspect: _ui_no_icon.width/_ui_no_icon.height }, 
  'ui/money': { src: _ui_money.src, source: isServer && _ui_moneySource, width: _ui_money.width, height: _ui_money.height, aspect: _ui_money.width/_ui_money.height }, 
  'ui/logo': { src: _ui_logo.src, source: isServer && _ui_logoSource, width: _ui_logo.width, height: _ui_logo.height, aspect: _ui_logo.width/_ui_logo.height }, 
  'ui/locked': { src: _ui_locked.src, source: isServer && _ui_lockedSource, width: _ui_locked.width, height: _ui_locked.height, aspect: _ui_locked.width/_ui_locked.height }, 
  'ui/loading': { src: _ui_loading.src, source: isServer && _ui_loadingSource, width: _ui_loading.width, height: _ui_loading.height, aspect: _ui_loading.width/_ui_loading.height }, 
  'ui/loader': { src: _ui_loader.src, source: isServer && _ui_loaderSource, width: _ui_loader.width, height: _ui_loader.height, aspect: _ui_loader.width/_ui_loader.height }, 
  'ui/info': { src: _ui_info.src, source: isServer && _ui_infoSource, width: _ui_info.width, height: _ui_info.height, aspect: _ui_info.width/_ui_info.height }, 
  'ui/exit': { src: _ui_exit.src, source: isServer && _ui_exitSource, width: _ui_exit.width, height: _ui_exit.height, aspect: _ui_exit.width/_ui_exit.height }, 
  'ui/discord': { src: _ui_discord.src, source: isServer && _ui_discordSource, width: _ui_discord.width, height: _ui_discord.height, aspect: _ui_discord.width/_ui_discord.height }, 
  'ui/deposit-and-withdrawal': { src: _ui_deposit_and_withdrawal.src, source: isServer && _ui_deposit_and_withdrawalSource, width: _ui_deposit_and_withdrawal.width, height: _ui_deposit_and_withdrawal.height, aspect: _ui_deposit_and_withdrawal.width/_ui_deposit_and_withdrawal.height }, 
  'ui/coin': { src: _ui_coin.src, source: isServer && _ui_coinSource, width: _ui_coin.width, height: _ui_coin.height, aspect: _ui_coin.width/_ui_coin.height }, 
  'ui/code': { src: _ui_code.src, source: isServer && _ui_codeSource, width: _ui_code.width, height: _ui_code.height, aspect: _ui_code.width/_ui_code.height }, 
  'ui/close': { src: _ui_close.src, source: isServer && _ui_closeSource, width: _ui_close.width, height: _ui_close.height, aspect: _ui_close.width/_ui_close.height }, 
  'ui/circular-gear': { src: _ui_circular_gear.src, source: isServer && _ui_circular_gearSource, width: _ui_circular_gear.width, height: _ui_circular_gear.height, aspect: _ui_circular_gear.width/_ui_circular_gear.height }, 
  'ui/check': { src: _ui_check.src, source: isServer && _ui_checkSource, width: _ui_check.width, height: _ui_check.height, aspect: _ui_check.width/_ui_check.height }, 
  'ui/arrow-down-circle-outline': { src: _ui_arrow_down_circle_outline.src, source: isServer && _ui_arrow_down_circle_outlineSource, width: _ui_arrow_down_circle_outline.width, height: _ui_arrow_down_circle_outline.height, aspect: _ui_arrow_down_circle_outline.width/_ui_arrow_down_circle_outline.height }, 
  'tokens/xdai': { src: _tokens_xdai.src, source: isServer && _tokens_xdaiSource, width: _tokens_xdai.width, height: _tokens_xdai.height, aspect: _tokens_xdai.width/_tokens_xdai.height }, 
  'tokens/wxdai': { src: _tokens_wxdai.src, source: isServer && _tokens_wxdaiSource, width: _tokens_wxdai.width, height: _tokens_wxdai.height, aspect: _tokens_wxdai.width/_tokens_wxdai.height }, 
  'tokens/wif': { src: _tokens_wif.src, source: isServer && _tokens_wifSource, width: _tokens_wif.width, height: _tokens_wif.height, aspect: _tokens_wif.width/_tokens_wif.height }, 
  'tokens/weth': { src: _tokens_weth.src, source: isServer && _tokens_wethSource, width: _tokens_weth.width, height: _tokens_weth.height, aspect: _tokens_weth.width/_tokens_weth.height }, 
  'tokens/wchz': { src: _tokens_wchz.src, source: isServer && _tokens_wchzSource, width: _tokens_wchz.width, height: _tokens_wchz.height, aspect: _tokens_wchz.width/_tokens_wchz.height }, 
  'tokens/usdt': { src: _tokens_usdt.src, source: isServer && _tokens_usdtSource, width: _tokens_usdt.width, height: _tokens_usdt.height, aspect: _tokens_usdt.width/_tokens_usdt.height }, 
  'tokens/usdc': { src: _tokens_usdc.src, source: isServer && _tokens_usdcSource, width: _tokens_usdc.width, height: _tokens_usdc.height, aspect: _tokens_usdc.width/_tokens_usdc.height }, 
  'tokens/shib': { src: _tokens_shib.src, source: isServer && _tokens_shibSource, width: _tokens_shib.width, height: _tokens_shib.height, aspect: _tokens_shib.width/_tokens_shib.height }, 
  'tokens/popcat': { src: _tokens_popcat.src, source: isServer && _tokens_popcatSource, width: _tokens_popcat.width, height: _tokens_popcat.height, aspect: _tokens_popcat.width/_tokens_popcat.height }, 
  'tokens/pol': { src: _tokens_pol.src, source: isServer && _tokens_polSource, width: _tokens_pol.width, height: _tokens_pol.height, aspect: _tokens_pol.width/_tokens_pol.height }, 
  'tokens/pepe': { src: _tokens_pepe.src, source: isServer && _tokens_pepeSource, width: _tokens_pepe.width, height: _tokens_pepe.height, aspect: _tokens_pepe.width/_tokens_pepe.height }, 
  'tokens/floki': { src: _tokens_floki.src, source: isServer && _tokens_flokiSource, width: _tokens_floki.width, height: _tokens_floki.height, aspect: _tokens_floki.width/_tokens_floki.height }, 
  'tokens/eth': { src: _tokens_eth.src, source: isServer && _tokens_ethSource, width: _tokens_eth.width, height: _tokens_eth.height, aspect: _tokens_eth.width/_tokens_eth.height }, 
  'tokens/doge': { src: _tokens_doge.src, source: isServer && _tokens_dogeSource, width: _tokens_doge.width, height: _tokens_doge.height, aspect: _tokens_doge.width/_tokens_doge.height }, 
  'tokens/dai': { src: _tokens_dai.src, source: isServer && _tokens_daiSource, width: _tokens_dai.width, height: _tokens_dai.height, aspect: _tokens_dai.width/_tokens_dai.height }, 
  'tokens/chz': { src: _tokens_chz.src, source: isServer && _tokens_chzSource, width: _tokens_chz.width, height: _tokens_chz.height, aspect: _tokens_chz.width/_tokens_chz.height }, 
  'tokens/bonk': { src: _tokens_bonk.src, source: isServer && _tokens_bonkSource, width: _tokens_bonk.width, height: _tokens_bonk.height, aspect: _tokens_bonk.width/_tokens_bonk.height }, 
  'tokens/bnb': { src: _tokens_bnb.src, source: isServer && _tokens_bnbSource, width: _tokens_bnb.width, height: _tokens_bnb.height, aspect: _tokens_bnb.width/_tokens_bnb.height }, 
  'networks/solana': { src: _networks_solana.src, source: isServer && _networks_solanaSource, width: _networks_solana.width, height: _networks_solana.height, aspect: _networks_solana.width/_networks_solana.height }, 
  'networks/polygon': { src: _networks_polygon.src, source: isServer && _networks_polygonSource, width: _networks_polygon.width, height: _networks_polygon.height, aspect: _networks_polygon.width/_networks_polygon.height }, 
  'networks/linea': { src: _networks_linea.src, source: isServer && _networks_lineaSource, width: _networks_linea.width, height: _networks_linea.height, aspect: _networks_linea.width/_networks_linea.height }, 
  'networks/gnosis': { src: _networks_gnosis.src, source: isServer && _networks_gnosisSource, width: _networks_gnosis.width, height: _networks_gnosis.height, aspect: _networks_gnosis.width/_networks_gnosis.height }, 
  'networks/eth': { src: _networks_eth.src, source: isServer && _networks_ethSource, width: _networks_eth.width, height: _networks_eth.height, aspect: _networks_eth.width/_networks_eth.height }, 
  'networks/chiliz': { src: _networks_chiliz.src, source: isServer && _networks_chilizSource, width: _networks_chiliz.width, height: _networks_chiliz.height, aspect: _networks_chiliz.width/_networks_chiliz.height }, 
  'networks/bnb': { src: _networks_bnb.src, source: isServer && _networks_bnbSource, width: _networks_bnb.width, height: _networks_bnb.height, aspect: _networks_bnb.width/_networks_bnb.height }, 
  'networks/base': { src: _networks_base.src, source: isServer && _networks_baseSource, width: _networks_base.width, height: _networks_base.height, aspect: _networks_base.width/_networks_base.height }, 
  'networks/arbitrum': { src: _networks_arbitrum.src, source: isServer && _networks_arbitrumSource, width: _networks_arbitrum.width, height: _networks_arbitrum.height, aspect: _networks_arbitrum.width/_networks_arbitrum.height }, 
  'bg-digits/two': { src: _bg_digits_two.src, source: isServer && _bg_digits_twoSource, width: _bg_digits_two.width, height: _bg_digits_two.height, aspect: _bg_digits_two.width/_bg_digits_two.height }, 
  'bg-digits/three': { src: _bg_digits_three.src, source: isServer && _bg_digits_threeSource, width: _bg_digits_three.width, height: _bg_digits_three.height, aspect: _bg_digits_three.width/_bg_digits_three.height }, 
  'bg-digits/one': { src: _bg_digits_one.src, source: isServer && _bg_digits_oneSource, width: _bg_digits_one.width, height: _bg_digits_one.height, aspect: _bg_digits_one.width/_bg_digits_one.height }, 
  'bg-digits/four': { src: _bg_digits_four.src, source: isServer && _bg_digits_fourSource, width: _bg_digits_four.width, height: _bg_digits_four.height, aspect: _bg_digits_four.width/_bg_digits_four.height }, 
  'backers/yelay': { src: _backers_yelay.src, source: isServer && _backers_yelaySource, width: _backers_yelay.width, height: _backers_yelay.height, aspect: _backers_yelay.width/_backers_yelay.height }
} as const

export type IconName = keyof typeof icons
export default icons